import * as React from "react";
const NotFoundPage = () => {
  return (
    <main>
      <h1>404 - Sorry Page not found</h1>
    </main>
  );
};

export default NotFoundPage;
